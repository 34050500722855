.shortcuts-container {
  width: 80%;
  height: 300px;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .shortcut {
    @include flex-direction-column;
    @include flex-center;
    width: 15%;
    padding: 2%;
    height: 40%;
    position: relative;
    .shortcut__three-dots-button {
      position: absolute;
      right: -4px;
      top: 0;
      display: none;
    }

    .shortcut__logo-container {
      @include flex-center;
      background: #eeeeee;
      padding: 13px;
      border-radius: 50%;
      margin-top: 20px;
      .shortcut__logo {
        width: 20px;
      }
    }
    .shortcut__text {
      text-align: center;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      color: $secondary;
      font-weight: 900;
      line-height: 24px;
      pointer-events: none;
      text-shadow: 0 0 14px rgb(0 0 0 / 60%);
      font-family: Arial;
      font-size: 12px;
    }
  }
  .shortcut:hover {
    cursor: pointer;
    background: rgba(100, 100, 120, 0.2);
    border-radius: 4px;
    .shortcut__three-dots-button {
      display: block;
    }
  }
}

@media only screen and (max-width: 480px) {
  .shortcuts-container {
    .shortcut {
      align-items: stretch;
    }
  }
}
