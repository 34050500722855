.search-bar {
  @include align-items(center);
  display: flex;
  height: 44px;
  width: 584px;
  margin: 2vh 5%;
  background: $secondary;
  border: 1px solid transparent;
  border-radius: 24px;
  padding: 0% 3%;
  transition: width 0.5s;
  .search-bar__text-input {
    border: none;
    width: 100%;
    font-size: 16px;
  }
  .search-bar__text-input:focus {
    border: none;
    outline: none;
  }
  .search-bar__icons {
    display: flex;
    position: relative;
    left: 10px;

    .search-bar__delete-search {
      width: 25px;
      cursor: pointer;
    }
    .vertical-separator {
      border-left: 1px solid #dadce0;
      height: 24px;
      margin: 0px 8px;
    }
  }
  .search-bar__search {
    width: 25px;
    cursor: pointer;
  }
}
.search-bar--expanded {
  width: 800px;
}

@media only screen and (max-width: 480px) {
  .search-bar {
    width: 70%;
    padding: 0% 5%;
    .search-bar__text-input {
      width: 250px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
