.nav-bar {
  display: flex;
  justify-content: space-around;
  width: 48vw;
  margin-left: 12vw;
  max-width: 584px;
  .nav-bar__option {
    display: flex;
    align-items: center;
    font-size: 14px;
    .nav-bar__option-icon {
      margin: 20px 10px;
      opacity: 0.6;
    }
  }
}

@media only screen and (max-width: 480px) {
  .nav-container {
    position: relative;
    .nav-bar__shadow {
      background: -webkit-linear-gradient(0deg, rgba(255, 255, 255, 0), #fff);
      height: 60px;
      position: absolute;
      pointer-events: none;
      right: 0;
      width: 29px;
      z-index: 2;
    }
    .nav-bar {
      width: 100%;
      margin-left: -5px;
      overflow-x: scroll;
      justify-content: space-between;
      &::-webkit-scrollbar {
        display: none;
      }
      .nav-bar__option {
        font-size: 13px;
      }
    }
  }
}
