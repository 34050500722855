.side-container {
  display: none;
  .nav-menu-items {
    list-style: none;
  }
}

@media only screen and (max-width: 480px) {
  .side-container {
    display: flex;

    .side-bar {
      height: 100px;
      display: flex;
      justify-content: start;
      align-items: center;
      position: absolute;
      font-size: 1.5rem;
    }

    .nav_menu__container {
      background-color: white;
      width: 100%;
      .logo__container {
        margin: 11px 0 0 11px;
        display: flex;
        align-items: center;
        margin-right: 53px;
        .side__logo {
          width: 8vw;
        }
        .side__logo-text {
          font-family: 'Graphik';
          font-weight: 1000;
          font-size: 1.5em;
          color: #000000;
        }
      }
      .nav-menu-items {
        margin: 11px 0 0 7px;
        .navbar-toggle {
          flex-direction: column;
          display: flex;
          justify-content: start;
          margin-top: 2;
          span {
            color: #999999;
            margin-left: 16px;
            font-size: 15px;
          }
        }
      }
    }
    .menu-bars-burger {
      margin-left: 2rem;
    }
    .menu-bars {
      text-decoration: none !important;
      margin: 20px 0 1rem -34px;
      font-size: 25px;
      display: flex;
      align-items: center;
      color: rgba(0, 0, 0, 0.54);
      width: 100%;
    }
    .nav-menu {
      width: 250px;
      height: 100vh;
      display: flex;
      justify-content: center;
      position: fixed;
      top: 0;
      left: -100%;
      transition: 850ms;
      z-index: 3;
      &.active {
        left: 0;
        transition: 350ms;
        background-color: #fff;
        z-index: 4;
        .backDrop {
          width: 100%;
          height: 100vh;
          background: rgba(0, 0, 0, 0.5);
          z-index: -1;
          position: fixed;
          top: 0;
          left: 0;
        }
      }
    }
  }
}
