.search-results {
  width: 50%;

  .search-result {
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    border: 1px solid #dadce0;
    border-radius: 8px;
    box-shadow: none;
    margin: 16px 8px;
    height: 180px;
    position: relative;
    max-height: 180px;
    transition: 0.25s;
    .search-result__text {
      transition: 0.25s;
      width: 60%;
      height: 100%;
      padding: 2% 0%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-family: arial, sans-serif;
      .search-result__author {
        color: #202124;
        font-family: arial, sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        position: relative;
        bottom: 2px;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-family: arial, sans-serif;
        cursor: pointer;

        .search-result__favicon {
          width: 25px;
          margin-right: 5px;
        }
      }
      .search-result__title {
        font-size: 18px;
        line-height: 1.33em;
        font-family: Roboto-Medium, sans-serif;
        color: #202124;
        font-weight: normal;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        overflow: hidden;
        -webkit-box-orient: vertical;
        font-family: arial, sans-serif;
        cursor: pointer;
        text-decoration: none;
      }
      .search-result__research-title {
        cursor: initial;
      }

      .search-result__claim {
        font-size: 14px;
        line-height: 1.43em;
        margin: 0;
        color: #70757a;
        margin-top: 8px;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        overflow: hidden;
        -webkit-box-orient: vertical;
        white-space: normal;
        font-family: arial, sans-serif;
        cursor: pointer;
        overflow-wrap: break-word;
        .search-result__rating {
          font-weight: bold;
        }
        .search-result__rating-explanation {
          margin-top: 5px;
          margin-bottom: 0;
        }
      }

      .search-result__research-search-result {
        font-size: 12px;
        -webkit-line-clamp: 5;
        overflow: 'hidden';
        cursor: initial;
        a {
          color: #1976d2;
        }
      }
      .search-result__votes {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .search-result__down-vote {
          display: flex;
          align-items: center;
        }
        .search-result__up-vote {
          display: flex;
          align-items: center;
        }
        .search-result__vote-number {
          font-size: 0.7em;
          margin: 0px 5px;
          font-family: 'Roboto', 'Arial', sans-serif;
          font-weight: 400;
          color: #606060;
        }
        .search-result__vote-button {
          width: 16px;
          border-radius: 50%;
          fill: #70757a;
          cursor: pointer;
        }
        .search-result__vote-button:focus {
          outline: none;
        }
        .search-result__vote-button-pressed {
          fill: #4285f4;
        }
      }
    }
    .search-result__research-text {
      justify-content: center;

      .search-result__view-more {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: none;
        position: absolute;
        bottom: -12px;
        right: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: background-color 0.2s;
        box-shadow: 0 1px 3px 1px rgb(64 60 67 / 24%);
        .search-result__view-more-icon {
          width: 20px;
          height: 20px;
        }
        .search-result__view-more-icon:hover {
          -webkit-filter: invert(1);
          filter: invert(1);
        }
      }
      .search-result__view-more:hover {
        background-color: #4285f4;
      }
    }
    .search-result__img {
      width: 112px;
      height: 112px;
      object-fit: cover;
      border-radius: 8px;
      cursor: pointer;
    }
    .search-result__research-img {
      cursor: initial;
    }
    .search-result__score {
      position: absolute;
      top: 2%;
      right: 2%;
      color: #70757a;
      font-size: 0.6em;
    }
    .search-result__newtral-score {
      position: absolute;
      bottom: 4%;
      right: 2%;
      width: 20px;
    }
  }
  .search-result-expanded {
    max-height: 600px;
    height: unset;
    transition: 0.25s;
    .search-result__research-text {
      .search-result__research-search-result {
        -webkit-line-clamp: unset;
        overflow: unset;
      }
    }
  }
  .search-results-articles-list {
    .search-results-articles-list-title {
      margin-top: 70px;
      margin-bottom: 30px;
    }
    .search-result__article-search-result {
      position: relative;
      bottom: 10px;
    }
  }
}
@media only screen and (max-width: 480px) {
  .search-results-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .search-results {
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
  }
}
