/*
  -
  Prefix
  -

  Example:

  .foo {
    @include prefix(transform, rotate(90deg), ('webkit', 'ms', 'moz', 'o'));
  }

*/

@mixin prefix($property, $value, $prefixes: ()) {
  @each $prefix in $prefixes {
    -#{$prefix}-#{$property}: $value;
  }

  #{$property}: $value;
}

/*
    -
    Generals
    -
  */

@mixin after() {
  &:after {
    content: '';
    @content;
  }
}

@mixin before() {
  &:before {
    content: '';
    @content;
  }
}

@mixin transition($transition...) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}

// foreach

@mixin each($rule, $number, $value) {
  @for $i from 1 to ($number + 1) {
    &:nth-child(#{$i}) {
      -webkit-#{$rule}: (#{$i * $value});
      #{$rule}: (#{$i * $value});
    }
  }
}

/*
    -
    Colors
    -
  */

@mixin bg-color-primary() {
  background-color: $primary;
}

@mixin color-primary() {
  color: $primary;
}

@mixin border-color-primary($position) {
  border-#{$position}-color: $primary;
}

@mixin bg-color-secondary() {
  background-color: $secondary;
}

@mixin color-secondary() {
  color: $secondary;
}

@mixin border-color-secondary($position) {
  border-#{$position}-color: $secondary;
}

@mixin bg-color-tertiary() {
  background-color: $tertiary;
}

@mixin color-tertiary() {
  color: $tertiary;
}

@mixin border-color-tertiary($position) {
  border-#{$position}-color: $tertiary;
}

@mixin bg-color-quaternary() {
  background-color: $quaternary;
}

@mixin color-quaternary() {
  color: $quaternary;
}

@mixin border-color-quaternary($position) {
  border-#{$position}-color: $quaternary;
}
/*
    -
    Positioning
    -
  */

@mixin absolute {
  position: absolute;
  top: 0;
  left: 0;
  width: $unit100p;
  height: $unit100p;
}

@mixin absolute-center {
  position: absolute;
  top: $unit50p;
  left: $unit50p;
  transform: translate(-$unit50p, -$unit50p);
  -webkit-transform: translate(-$unit50p, -$unit50p);
}

@mixin absolute-reset {
  position: relative !important;
  top: auto !important;
  bottom: auto !important;
  left: auto !important;
  right: auto !important;
  transform: none !important;
  -webkit-transform: none !important;
}

@mixin fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: $unit100p;
  z-index: 30;
}

@mixin full-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: $unit100p;
  height: $unit100p;
}

/*
    -
    Transforms
    -
  */

@mixin skew($value) {
  -webkit-transform: skew($value);
  -ms-transform: skew($value);
  transform: skew($value);
}

@mixin skewY($value) {
  -webkit-transform: skewY($value);
  -ms-transform: skewY($value);
  transform: skewY($value);
}

@mixin skewX($value) {
  -webkit-transform: skewX($value);
  -ms-transform: skewX($value);
  transform: skewX($value);
}

/*
    -
    Styles
    -
  */

@mixin cover {
  background-position: $unit50p $unit50p;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

@mixin break-inside {
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}

@mixin hideit {
  visibility: hidden;
  display: none;
  opacity: 0;
}

@mixin filter($filter-type, $filter-amount) {
  -webkit-filter: $filter-type + unquote('(#{$filter-amount})');
  -moz-filter: $filter-type + unquote('(#{$filter-amount})');
  -ms-filter: $filter-type + unquote('(#{$filter-amount})');
  -o-filter: $filter-type + unquote('(#{$filter-amount})');
  filter: $filter-type + unquote('(#{$filter-amount})');
}

@mixin gradient($direction, $list) {
  background: -webkit-linear-gradient($direction, $list);
  background: -moz-linear-gradient($direction, $list);
  background: -o-linear-gradient($direction, $list);
  background: linear-gradient($direction, $list);
}

@mixin placeholder {
  &.placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }
}

@mixin placeholder-color($color) {
  &:-moz-placeholder {
    color: $color;
  }

  &::-moz-placeholder {
    color: $color;
  }

  &:-ms-input-placeholder {
    color: $color;
  }

  &::-webkit-input-placeholder {
    color: $color;
  }
}

@mixin object-fit {
  @include absolute;
  -o-object-fit: cover;
  object-fit: cover;
}

/*
    -
    Links
    -
  */

@mixin touch-hover() {
  &:hover,
  &:focus {
    @content;
  }
}

@mixin link() {
  &:link,
  &:visited {
    @content;
  }
}

@mixin underline($color, $height) {
  position: relative;
  display: inline-block;
  @include prefix(transition, $ts-soft, ('webkit', 'ms', 'moz', 'o'));

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    width: 0%;
    height: $height;
    background: $color;
    @include prefix(transition, width $ts-soft, ('webkit', 'ms', 'moz', 'o'));
  }

  @include touch-hover() {
    text-decoration: none;

    &:after {
      width: 100%;
    }
  }
}

@mixin inverted-underline($color, $height) {
  position: relative;
  display: inline-block;
  @include prefix(transition, $ts-soft, ('webkit', 'ms', 'moz', 'o'));

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: $height;
    background: $color;
    @include prefix(transition, width $ts-soft ease-in-out, ('webkit', 'ms', 'moz', 'o'));
  }

  @include touch-hover() {
    text-decoration: none;

    &:after {
      width: 0;
    }
  }
}

@mixin centered-underline($color, $height) {
  position: relative;
  display: inline-block;
  line-height: $lh-big;
  @include prefix(transition, $ts-soft, ('webkit', 'ms', 'moz', 'o'));

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: $unit50p;
    @include transform-translate(-$unit50p, 0);
    width: 0;
    height: $height;
    background: $color;
    @include prefix(transition, width $ts-soft ease-in-out, ('webkit', 'ms', 'moz', 'o'));
  }

  @include touch-hover() {
    text-decoration: none;

    &:after {
      width: $unit100p;
    }
  }
}

@mixin line-motion($color, $height) {
  position: relative;
  display: inline-block;
  @include transition($ts-soft);

  &:before,
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: $unit100p;
    height: $height;
    background-color: $color;
    @include transition(left $ts-soft ease-in-out);
  }

  $after-before-position: 110%;

  &:before {
    left: -$after-before-position;
  }

  &:after {
    left: 0;
  }

  @include touch-hover() {
    &:before {
      left: 0;
    }

    &:after {
      left: $after-before-position;
    }
  }
}

/*
    -
    Flexbox
    -
  */

@mixin flex-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@mixin flex-between {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-between;
  -ms-flex-pack: space-between;
  justify-content: space-between;
}

@mixin flex-between-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: space-between;
  -ms-flex-pack: space-between;
  justify-content: space-between;
}

@mixin display-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@mixin align-items($variable) {
  -webkit-box-align: $variable;
  -ms-flex-align: $variable;
  align-items: $variable;
}

@mixin align-self($variable) {
  -ms-flex-item-align: $variable;
  -ms-grid-row-align: $variable;
  align-self: $variable;
}

@mixin flex-wrap($variable) {
  -ms-flex-wrap: $variable;
  flex-wrap: $variable;
}

@mixin flex-direction-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

@mixin flex-direction-column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

@mixin justify-content($varible) {
  -webkit-box-pack: $varible;
  -ms-flex-pack: $varible;
  justify-content: $varible;
}

@mixin flex-order($variable) {
  -webkit-box-ordinal-group: $variable;
  -ms-flex-order: $variable;
  order: $variable;
}

@mixin flex($varible1, $variable2, $variable3) {
  -webkit-box-flex: $varible1;
  -ms-flex: $varible1 $variable2 $variable3;
  flex: $varible1 $variable2 $variable3;
}

@mixin total-size($value) {
  min-width: $value;
  width: $value;
  max-width: $value;
  min-height: $value;
  height: $value;
  max-height: $value;
}

/*
    -
    Grid
    -
  */

@mixin display-grid {
  display: -ms-grid;
  display: grid;
}

@mixin grid-wrapper($columns, $gap: null) {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: $columns;
  grid-template-columns: $columns;
  -webkit-column-gap: $gap;
  -moz-column-gap: $gap;
  column-gap: $gap;
}

@mixin grid-column($start, $end) {
  grid-column: #{$start}/#{$end};
  -ms-grid-column: $start;
  -ms-grid-column-span: $end;
}

@mixin align-content($variable) {
  -ms-flex-line-pack: $variable;
  align-content: $variable;
}

@mixin align-self($variable) {
  -ms-flex-item-align: $variable;
  -ms-grid-row-align: $variable;
  align-self: $variable;
}

/*
    -
    String Replace
    -
  */

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}
/*
    -
    Animations
    -
  */

@mixin fade($fade, $timing, $length, $count, $fill) {
  animation: $fade $timing $length;
  animation-iteration-count: $count;
  animation-fill-mode: $fill;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@mixin lightOn($timing, $length, $count, $fill) {
  animation: lightOn $timing $length;
  animation-iteration-count: $count;
  animation-fill-mode: $fill;
}
@keyframes lightOn {
  0%,
  20%,
  22%,
  25% {
    filter: grayscale(100%);
  }
  21.999%,
  24.999%,
  40%,
  100% {
    filter: grayscale(0%);
  }
}

/*
    -
    Font face
    -
  */

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
  $src: null;

  $extmods: (
    eot: '?',
    svg: '#' + str-replace($name, ' ', '_')
  );

  $formats: (
    otf: 'opentype',
    ttf: 'truetype'
  );

  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + '.' + $extmod)) format(quote($format)), comma);
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}

/*
    -
    Animations
    -
  */
