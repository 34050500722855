body {
  margin: 0;
  color: $secondary;
  font-family: 'Arial';
}
.search-engine {
  height: 100vh;
  @include flex-center;
}
.search-engine__background {
  height: 100%;
  width: 100%;
  background-image: url('../img/backgrounds/newtral-background.jpg');
  background-size: cover;
  position: fixed;
  top: 0;
  z-index: 0;
  filter: blur(3px) grayscale(40%) brightness(80%);
  transition: filter 0.5s;
}
.search-engine__background--unfocused {
  filter: blur(10px) grayscale(60%) brightness(60%);
}

