// Colors

$primary: #000000;
$secondary: #ffffff;
$tertiary: #05f3b3;
$quaternary: #fe2862;
$quinary: #fecf27;
$graypill: #757575;
$graypill-background: #212121;
$gray: #444444;
$mid-gray: #bebebe;
$light-gray: #dddddd;
$footer-link: #1f1f1f;

/*
	-
	Typos
	-
*/

$ff-main: 'Graphik', sans-serif;

$text-indent: -9999px;

/*
	-
	Sizes
	-
*/

$fs-xxxxxl: 40px;
$fs-xxxxl: 36px;
$fs-xxxl: 32px;
$fs-xxl: 24px;
$fs-xl: 22px;
$fs-l: 18px;
$fs-m: 16px;
$fs-ms: 15px;
$fs-s: 14px;
$fs-xs: 12px;

$v-unit-xs: 8px;
$v-unit: 16px;
$v-unit-m: 20px;
$v-unit-l: 32px;
$v-unit-xl: 64px;
$v-unit-xxl: 96px;
$v-unit-xxxl: 120px;

$h-unit-xs: 8px;
$h-unit: 16px;
$h-unit-m: 20px;
$h-unit-l: 32px;
$h-unit-xl: 64px;
$h-unit-xxl: 96px;
$h-unit-xxxl: 120px;
$h-unit-xxxxl: 160px;

$unit100p: 100%;
$unit75p: 75%;
$unit66p: 66.66667%;
$unit60p: 60%;
$unit50p: 50%;
$unit45p: 45%;
$unit40p: 40%;
$unit33p: 33.33333%;
$unit25p: 25%;
$unit-video: 56.25%;

$unit56: 56px;
$unit48: 48px;
$unit40: 40px;
$unit24: 24px;
$unit12: 12px;
$v100: 100vh;

/*
	-
	Styles
	-
*/

$fw-black: 900;
$fw-extrabold: 800;
$fw-bold: 700;
$fw-semibold: 600;
$fw-medium: 500;
$fw-normal: 400;
$fw-light: 300;
$fw-thin: 100;

$fs-italic: italic;
$fs-normal: normal;

$lh-extra: 2;
$lh-big: 1.75;
$lh-read: 1.6667;
$lh-medium: 1.5;
$lh-standard: 1.33333;
$lh-normal: 1.2;
$lh-small: 1.142;
$lh-reset: 1;
$lh-none: 0;

$ls-minor: 0.5px;
$ls-small: 1px;
$ls-medium: 2px;
$ls-big: 3px;
$ls-extra: 4px;

$padding-xs: 5px;
$padding-sm: 10px;
$padding-md: 20px;

$margin-xs: 5px;
$margin-sm: 10px;
$margin-md: 20px;

$border-xs: 1px solid;
$border-sm: 2px solid;
$border-md: 3px solid;

$border-radius: 4px;

$border-gray: 2px solid rgba(255, 255, 255, 0.2);

$ts-fast: 0.3s;
$ts-soft: 0.6s;
$ts-slow: 1s;

$opacity-full: 1;
$opacity-medium: 0.5;
$opacity-none: 0;

$rotate-hor: 180deg;
