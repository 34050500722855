#newtral-score-popup {
  position: fixed;
  top: 1%;
  background: #fff;
  z-index: 1000000;
  width: 20%;
  left: 40%;
  -webkit-box-shadow: 0 0 5px 0 rgb(0 0 0 / 20%) !important;
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 20%) !important;
  padding: 2% 2% 1% 2%;
  font-family: sans-serif !important;
  line-height: 1.5;
}
.popup__content {
  color: $primary;
  padding: 20px;
  text-align: center;
}
.popup__close-button {
  cursor: pointer;
  position: absolute;
  z-index: 999 !important;
  width: 26px;
  height: 20px;
  top: 5px;
  right: 0;
  border: 0;
  color: #333;
}

.popup__rating {
  display: flex;
  flex-direction: row;
  margin: 5% 0;
  align-items: center;
  justify-content: center;
}
.popup__rating-image {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popup__rating-icon {
  border-radius: 50%;
  padding: 8%;
  width: 32px !important;
  height: 32px !important;
}
.popup__rating-icon--reliable {
  background: #01f3b3;
}
.popup__rating-icon--unreliable {
  background: #e32323;
}
.popup__rating-icon--unknown {
  background: #4285f4;
}
.popup__rating-text {
  font-size: 16px;
  color: #181c32;
  font-weight: 500;
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.popup__rating-title {
  color: #a1a5b7;
  font-family: sans-serif !important;
  font-size: 14px;
}
.popup__rating-percentage {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 16px;
}
.popup__separator {
  border-bottom: solid 1px #dadce0;
  width: 80%;
  margin-left: 10%;
}
.popup__newtral-score {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.popup__newtral-score-text {
  font-weight: 500;
  font-size: 18px;
  color: #000;
}
.popup__newtral-score-value {
  font-size: 42px;
  font-weight: 500;
}
.reliable {
  color: #01f3b3;
}
.unreliable {
  color: #e32323;
}
.unknown {
  color: #4285f4;
}
.popup__claim-review {
  display: flex;
  justify-content: space-around;
  cursor: pointer;
}
.popup__claim-review-author {
  color: rgb(32, 33, 36);
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
  bottom: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-family: arial, sans-serif;
  width: 20%;
}
.popup__claim-review-author-favicon {
  margin-right: 10px;
}
.popup__claim-review-rating {
  width: 80%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 16px;
  color: $graypill;
  font-size: 14px;
}
