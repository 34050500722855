.search-header-search-bar {
  @include align-items(center);
  display: flex;
  height: 44px;
  margin: 2vh 1%;
  background: $secondary;
  border: 1px solid $light-gray;
  border-radius: 24px;
  padding: 0% 3%;
  width: 48vw;
  max-width: 584px;
  box-sizing: border-box;
  .search-header-search-bar__text-input {
    border: none;
    width: 100%;
    font-size: 16px;
  }
  .search-header-search-bar__text-input:focus {
    border: none;
    outline: none;
  }
  .search-header-search-bar__icons {
    display: flex;
    position: relative;
    left: 10px;
    .search-header-search-bar__delete-search {
      width: 25px;
      cursor: pointer;
    }
    .vertical-separator {
      border-left: 1px solid #dadce0;
      height: 24px;
      margin: 0px 8px;
    }
  }
  .search-header-search-bar__search {
    width: 25px;
    cursor: pointer;
  }
}
.search-header-search-bar--expanded {
  width: 800px;
}
.search-header-search-bar:hover {
  box-shadow: 0 2px 8px 1px rgb(64 60 67 / 24%);
  border-color: rgba(223, 225, 229, 0);
}

@media only screen and (max-width: 480px) {
  .search-header-search-bar {
    .search-header-search-bar__text-input {
      font-size: 14px;
      width: 250px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .search-header-search-bar__icons {
      left: 15px;
    }
  }
}
