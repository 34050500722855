.searcher {
  @include flex-center;
  @include flex-direction-column;
  z-index: 1;
  width: 50%;
  .searcher__header {
    .searcher__title {
      @include flex-center;
      height: 85px;
      cursor: pointer;
      .searcher__logo {
        height: 60px;
      }
      .searcher__name {
        font-family: 'Graphik';
        color: $secondary;
        font-size: 4em;
      }
    }
    .searcher__fact-checks-counter-container {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: flex-end;
      font-size: 18px;
      opacity: 0.8;
      cursor: pointer;
      .searcher__fact-checks-count {
        margin: 0 10px;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .search {
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .searcher {
    width: 100%;
    .searcher__header {
      height: 188px;
      .searcher__title {
        .searcher__name {
          margin: 0;
          font-size: 3.4em;
        }
      }
      .searcher__fact-checks-counter-container {
        align-items: center;
        .searcher__fact-checks-count {
          margin: 0 10px;
        }
      }
    }
  }
}
