.user-menu {
  display: flex;
  position: absolute;
  width: 50%;
  height: 100px;
  justify-content: flex-end;
  align-items: center;
  top: 0;
  right: 0;
  z-index: 1;
  font-weight: 400;
  font-size: 14px;
  .user-menu__option {
    width: 10%;
    cursor: pointer;
  }
  .user-menu__profile-picture {
    width: 45%;
    border-radius: 50%;
    border: solid 2px white;
  }
}

@media only screen and (max-width: 480px) {
  .user-menu {
    width: 100vw;
    justify-content: flex-end;
    .user-menu__option {
      width: 14%;
      margin-right: 0;
    }
  }
}
