.search {
  color: $graypill;
  .search-header {
    border-bottom: solid 1px $light-gray;

    .search-header__main {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .search-header__search-bar-container {
        display: flex;
        align-items: center;
        width: 60vw;
        top: 5px;
        position: absolute;
        .search-header__logo-container {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 12vw;
          cursor: pointer;
          .search-header__logo {
            width: 30%;
          }
          .search-header__logo-text {
            font-family: 'Graphik';
            font-weight: 1000;
            font-size: 1.5em;
            color: $primary;
          }
        }
      }
    }
  }
  .search-header__multilanguage-switch {
    margin-left: 13vw;
    position: relative;
    bottom: 2vh;
  }
  .search-results-container {
    padding: 0% 5%;
    .search-info {
      margin-bottom: 15px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .search {
    display: flex;
    justify-content: center;
    flex-direction: column;
    .search-header__multilanguage-switch {
      margin-left: 9vw;
      bottom: 1vh;
    }
    .search-header__search-bar-container {
      top: 0 !important;
      position: relative !important;
      .search-header__logo-container {
        font-size: 2em;
        .search-header__logo {
          width: 15vw !important;
        }
      }
    }
  }
}
