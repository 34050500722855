.search-header-user-menu {
  display: flex;
  width: 100%;
  height: 100px;
  justify-content: flex-end;
  align-items: center;
  z-index: 1;
  font-weight: 400;
  font-size: 14px;
  .search-header-user-menu__option {
    width: 4%;
    cursor: pointer;
    .search-header-user-menu__apps-icon {
      opacity: 0.4;
    }
  }
  .search-header-user-menu__profile-picture {
    width: 47%;
    border-radius: 50%;
    border: solid 2px white;
  }
}

@media only screen and (max-width: 480px) {
  .search-header__main {
    flex-direction: column;
    width: 100%;
    .search-header__search-bar-container {
      flex-direction: column;
      height: 100%;
      .search-header-search-bar {
        width: 91vw;
        padding: 0% 6%;
      }
    }
  }
  .search-header-user-menu {
    width: 100%;
    .search-header-user-menu__option {
      visibility: hidden;
      margin-right: 0;
      width: 14%;
    }
    .search-header-user-menu__profile-picture {
      width: 54%;
      visibility: visible !important;
    }
  }
}
